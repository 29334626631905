import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  PageHeader,
  Space,
  Tag,
  Table,
  Typography,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from 'state/users';
import { ITEMS_PER_PAGE } from 'utils/constants';

const { Link: TLink } = Typography;

const columns = [
  {
    title: 'Name',
    dataIndex: 'telegramName',
    key: 'telegramName',
  },
  {
    title: 'Username',
    dataIndex: 'telegramUserName',
    key: 'telegramUserName',
    render: (text) => <TLink href={`https://t.me/${text}`} target="_blank" rel="noopener noreferrer">{`@${text}`}</TLink>,
  },
  {
    title: 'Login Status',
    dataIndex: 'isLoggedIn',
    key: 'isLoggedIn',
    render: (a) => {
      if (a) {
        return <Tag color="green">Logged In</Tag>;
      }
      return <Tag color="red">Logout</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'isDisabled',
    key: 'isDisabled',
    render: (a, record) => {
      const tags = [
        a ? <Tag color="red">Disabled</Tag> : <Tag color="green">Active</Tag>,
        record?.isUnderControl ? <Tag color="orange">Under control</Tag> : null,
      ];

      return (
        <>
          {tags.map((tag) => tag)}
        </>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/users/${record._id}`}>View Details</Link>
      </Space>
    ),
  },
];

const UserScreen = () => {
  const {
    data,
    total,
    fetchLoading,
    sortBy,
    filters,
    offset,
    limit,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.fetchUsers({
      sortBy,
      filters,
      offset,
      limit,
    }));
  }, []);

  const handlePageChange = (newPage) => {
    dispatch(userActions.fetchUsers({
      sortBy,
      filters,
      offset: newPage - 1,
      limit,
    }));
    dispatch(userActions.updatePage(newPage - 1));
  };

  return (
    <>
      <PageHeader
        title="Users"
        subTitle="All telegram users registered with bot"
        style={{ backgroundColor: '#fff' }}
      />
      <div style={{ margin: 8, padding: 24, backgroundColor: '#fff' }}>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data}
          loading={fetchLoading}
          pagination={{
            current: offset + 1,
            pageSize: ITEMS_PER_PAGE,
            total,
            onChange: handlePageChange,
          }}
        />
      </div>
    </>
  );
};

export default UserScreen;
