import { configureStore } from '@reduxjs/toolkit';
import authSlice from 'state/auth';
import usersSlice from 'state/users';
import groupsSlice from 'state/groups';
import settingsSlice from 'state/settings';

export const resetAllState = () => {
  // will reset all state except auth state
};

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    users: usersSlice.reducer,
    groups: groupsSlice.reducer,
    settings: settingsSlice.reducer,
  },
});
