import { createSlice } from '@reduxjs/toolkit';
import { fetchSettings, updateSettings } from './service';

const initialState = {
  settings: {},

  fetchLoading: false,
  updateLoading: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: {
    // Fetch settings
    [fetchSettings.pending]: (state) => { state.fetchLoading = true; },

    [fetchSettings.fulfilled]: (state, { payload }) => {
      state.settings = payload;
      state.fetchLoading = false;
    },

    [fetchSettings.rejected]: (state) => { state.fetchLoading = false; },

    // Update settings
    [updateSettings.pending]: (state) => { state.updateLoading = true; },

    [updateSettings.fulfilled]: (state, { payload }) => {
      state.settings = payload;
      state.updateLoading = false;
    },

    [updateSettings.rejected]: (state) => { state.updateLoading = false; },
  },
});

export const settingsActions = {
  fetchSettings,
  updateSettings,
  ...settingsSlice.actions,
};

export default settingsSlice;
