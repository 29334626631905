import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  PageHeader,
  Tabs,
  Card,
  Button,
  message,
  Modal,
  Tag,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { userActions } from 'state/users';
import ApiService from 'services/APIService';
import UserInformation from './UserInformation';
import JoinedGroupList from './JoinedGroupList';

const { TabPane } = Tabs;
const { confirm } = Modal;

const UserDetailsScreen = () => {
  const { id: userId } = useParams();
  const history = useHistory();

  const { selectedUser: user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUser({ userId }));
  }, [userId]);

  const handleBack = () => {
    history.push('/users');
  };

  const sendPinResetMessage = async () => {
    try {
      await ApiService.post(`/users/${userId}/notify_reset_pin`);
      message.success('Pin reset message sent successfully.');
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const enableUser = async () => {
    try {
      await ApiService.post(`/users/${userId}/enable`);
      dispatch(userActions.updateDisabledStatus(false));
      message.success('User re-enabled successfully.');
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const disableUser = async () => {
    try {
      await ApiService.post(`/users/${userId}/disable`);
      dispatch(userActions.updateDisabledStatus(true));
      message.success('User disabled successfully.');
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const confirmPinResetMessage = async () => {
    confirm({
      title: 'Send pin reset message',
      icon: <ExclamationCircleOutlined />,
      content: 'Pin reset message will be sent to this user.',
      onOk: sendPinResetMessage,
      onCancel() { },
      okText: 'Send',
    });
  };

  const confirmEnableUser = () => {
    confirm({
      title: 'Enable User',
      icon: <ExclamationCircleOutlined />,
      content: 'This user will be able to interact with the bot again.',
      onOk: enableUser,
      onCancel() { },
      okText: 'Enable',
    });
  };

  const confirmDisableUser = () => {
    confirm({
      title: 'Disable User',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <span>This user will not be able to interact with the bot anymore.</span>
          <br />
          <span>The user will also be removed from all groups.</span>
        </>
      ),
      onOk: disableUser,
      onCancel() { },
      okText: 'Disable',
    });
  };

  const markAsUC = async () => {
    try {
      await ApiService.post(`/users/${userId}/mark_uc`);
      dispatch(userActions.updateUCStatus(true));
      message.success('User marked as under control.');
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const unmarkAsUC = async () => {
    try {
      await ApiService.post(`/users/${userId}/unmark_uc`);
      dispatch(userActions.updateUCStatus(false));
      message.success('User is no longer marked as under control.');
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  const getExtraButtons = () => {
    let buttons = [];

    if (user?.isUnderControl) {
      buttons = [
        ...buttons,
        <Button key="0" onClick={unmarkAsUC}>Un-mark under control</Button>,
      ];
    } else {
      buttons = [
        ...buttons,
        <Button key="0" onClick={markAsUC}>Mark as under control</Button>,
      ];
    }

    if (user?.isDisabled) {
      buttons = [
        ...buttons,
        <Button key="1" onClick={confirmEnableUser} type="primary" ghost>Enable User</Button>,
      ];
    } else {
      buttons = [
        ...buttons,
        <Button key="1" onClick={confirmDisableUser} danger>Disable User</Button>,
        <Button key="2" onClick={confirmPinResetMessage}>Send Pin Reset Msg.</Button>,
      ];
    }

    return buttons;
  };

  const getTags = () => ([
    user?.isDisabled ? <Tag color="red">Disabled</Tag> : <Tag color="green">Active</Tag>,
    user?.isUnderControl ? <Tag color="orange">Under Control</Tag> : null,
  ]);

  return (
    <>
      <PageHeader
        title={`Users Details - ${user?.telegramName}`}
        onBack={handleBack}
        style={{ backgroundColor: '#fff' }}
        footer={(
          <Tabs defaultActiveKey="1">
            <TabPane tab="Groups" key="1" />
          </Tabs>
        )}
        extra={getExtraButtons()}
        tags={getTags()}
      >
        <UserInformation />
      </PageHeader>
      <div style={{ margin: 24, backgroundColor: '#fff' }}>
        <Card title="Group List" bordered={false}>
          <JoinedGroupList userId={userId} />
        </Card>
      </div>
    </>
  );
};

export default UserDetailsScreen;
