import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Card,
  message,
} from 'antd';
import ApiService from 'services/APIService';
import { authActions } from 'state/auth';

const { Title } = Typography;

export default function LoginScreen() {
  const history = useHistory();
  const { loading, authenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (authenticated) {
      // let from = location.state || { from: { pathname: '/' } };
      const from = { from: { pathname: '/' } };

      history.replace(from.from.pathname);
    }
  }, [authenticated, user]);

  const handleLogin = async (credentials) => {
    try {
      dispatch(authActions.setLoading(true));
      const { data } = await ApiService.post('/auth/login', credentials);
      const { profile, creds } = data;

      localStorage.setItem('_cred', JSON.stringify(creds));

      dispatch(authActions.setAuthUser(profile));
      dispatch(authActions.setLoading(false));
    } catch (err) {
      message.error(err?.response?.data?.message);
      dispatch(authActions.setLoading(false));
    }
  };

  return (
    <Row style={{ height: '100vh' }} align="middle" justify="center">
      <Col span={6}>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <Title
              level={4}
              style={{ marginTop: 0, marginBottom: 16 }}
            >
              Log In
            </Title>
          </div>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleLogin}
          >
            <Form.Item
              name="id"
              label="ID"
              rules={[
                { required: true, message: 'Please enter ID.' },
              ]}
            >
              <Input placeholder="Enter ID" />
            </Form.Item>

            <Form.Item name="password" label="Password">
              <Input.Password placeholder="Enter password" rules={[{ required: true, message: 'Please enter password.' }]} />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ float: 'right' }}
              loading={loading}
            >
              Login
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
