import { useEffect, useState } from 'react';
import {
  PageHeader,
  Menu,
  Row,
  Col,
} from 'antd';
import { useDispatch } from 'react-redux';
import { settingsActions } from 'state/settings';
import SecuritySettings from './SecuritySettings';

export default function SettingsScreen() {
  const [selectedTab, setSelectedTab] = useState('security');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingsActions.fetchSettings());
  }, []);

  const buildBlocks = () => {
    if (selectedTab === 'security') {
      return <SecuritySettings />;
    }

    return <div />;
  };

  return (
    <>
      <PageHeader
        title="Settings"
        subTitle="Manage system preferences."
        style={{ backgroundColor: '#fff' }}
      />
      <div style={{ margin: 16, padding: '12px 0', backgroundColor: '#fff' }}>
        <Row>
          <Col span={4} style={{ borderRight: '1px solid #f0f0f0' }}>
            <Menu
              selectedKeys={[selectedTab]}
              onSelect={(info) => setSelectedTab(info.key)}
              mode="inline"
            >
              {/* <Menu.Item key="general">
                General
              </Menu.Item> */}
              <Menu.Item key="security">
                Security
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={20} style={{ padding: '24px 36px' }}>
            {buildBlocks()}
          </Col>
        </Row>
      </div>
    </>
  );
}
