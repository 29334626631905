import { Row, Col, Spin } from 'antd';

export default function AppLoading() {
  return (
    <Row style={{ height: '100vh' }} align="middle" justify="center">
      <Col>
        <Spin tip="App is loading..." />
      </Col>
    </Row>
  );
}
