import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Tag, Table,
} from 'antd';
import { STATUS_MAP as statusMap } from 'utils/constants';
import { getFormattedTime } from 'utils/helpers';
import { groupActions } from 'state/groups';

const GroupMembersModal = ({ visible, onClose, group }) => {
  const { groupParticipants, participantsLoading } = useSelector((state) => state.groups);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!group._id) return;

    dispatch(groupActions.getParticipants({ groupId: group?._id }));
  }, [group]);

  const columns = [
    {
      title: 'Name',
      dataIndex: ['user', 'telegramName'],
      key: ['user', 'telegramName'],
    },
    {
      title: 'Registered At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => getFormattedTime(text),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (a) => <Tag color={statusMap[a].color}>{statusMap[a].text}</Tag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => <Link to={`/users/${record?.user?._id}`}>View Details</Link>,
    },
  ];

  return (
    <Modal title={`${group?.name} - Members`} visible={visible} footer={null} onOk={onClose} onCancel={onClose}>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={groupParticipants}
        loading={participantsLoading}
        pagination={false}
      />
    </Modal>
  );
};

export default GroupMembersModal;
