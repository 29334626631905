import './App.less';
import { Provider } from 'react-redux';
import RouterConfig from 'navigation/RouterConfig';
import store from 'state/redux/store';

const App = () => (
  <Provider store={store}>
    <RouterConfig />
  </Provider>
);

export default App;
