import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/APIService';
import { handleAPIError } from 'utils/helpers';

export const fetchSettings = createAsyncThunk('settings/fetch',
  async (params, thunkAPI) => {
    try {
      const { data } = await ApiService.get('/settings/fetch_preferences');
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const updateSettings = createAsyncThunk('settings/update',
  async (update, thunkAPI) => {
    try {
      const { data } = await ApiService.put('/settings', update);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });
