import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ApiService from 'services/APIService';
import { authActions } from 'state/auth';

import AppLoading from 'components/AppLoading';
import AppLayout from 'modules/Layout';

import LoginScreen from 'modules/Auth/Login';
import UserScreen from 'modules/User';
import UserDetailsScreen from 'modules/User/UserDetails';
import GroupsScreen from 'modules/Group';
import SettingsScreen from 'modules/Settings';

import PrivateRoute from './PrivateRoute';
import NotFound from './NotFound';
import {
  LOGIN,

  ROOT,
  USERS,
  USER_DETAILS,

  SETTINGS,
} from './constants';

export default function RouterConfig() {
  const { appLoaded } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      const credsExist = localStorage.getItem('_cred');
      if (credsExist) {
        const { data: user } = await ApiService.get('/auth/me');
        dispatch(authActions.setAuthUser(user));
      } else {
        dispatch(authActions.startApp());
      }
    };

    init();
  }, []);

  if (!appLoaded) {
    return (
      <AppLoading />
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={LOGIN} component={LoginScreen} />

        {/* Private route */}
        <AppLayout>
          <PrivateRoute exact path={ROOT}>
            <GroupsScreen />
          </PrivateRoute>
          <PrivateRoute exact path={USERS}>
            <UserScreen />
          </PrivateRoute>
          <PrivateRoute exact path={USER_DETAILS}>
            <UserDetailsScreen />
          </PrivateRoute>
          <PrivateRoute exact path={SETTINGS}>
            <SettingsScreen />
          </PrivateRoute>
        </AppLayout>
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
