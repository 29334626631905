import { useEffect } from 'react';
import {
  Tag,
  Table,
  Button,
} from 'antd';
import { StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from 'state/users';
import { STATUS_MAP as statusMap } from 'utils/constants';
import { getFormattedTime } from 'utils/helpers';

const JoinedGroupList = ({ userId = '' }) => {
  const {
    joinedGroups,
    joinedGroupsLoading,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getJoinedGroups({ userId }));
  }, []);

  const banUser = (groupId) => {
    dispatch(userActions.banUser({ userId, groupId }));
  };

  const unbanUser = (groupId) => {
    dispatch(userActions.unbanUser({ userId, groupId }));
  };

  const columns = [
    {
      title: 'Group Name',
      dataIndex: ['group', 'name'],
      key: ['group', 'name'],
    },
    {
      title: 'Registered At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => getFormattedTime(text),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (a) => <Tag color={statusMap[a].color}>{statusMap[a].text}</Tag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'INACTIVE') {
          return (
            <Button type="link" icon={<CheckCircleOutlined />} style={{ padding: 0 }} onClick={() => unbanUser(record?.group?._id)}>
              Unban user
            </Button>
          );
        }

        return (
          <Button type="link" icon={<StopOutlined />} danger style={{ padding: 0 }} onClick={() => banUser(record?.group?._id)}>
            Ban user
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="_id"
      columns={columns}
      dataSource={joinedGroups}
      loading={joinedGroupsLoading}
    />
  );
};

export default JoinedGroupList;
