import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/APIService';
import { handleAPIError } from 'utils/helpers';

export const fetchUsers = createAsyncThunk('users/fetch',
  async ({
    filters, sortBy, offset, limit,
  }, thunkAPI) => {
    const queryString = qs.stringify({
      filters,
      sort_by: sortBy,
      offset: offset * limit,
      limit,
    }, { encode: false });

    try {
      const { data } = await ApiService.get(`/users?${queryString}`);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const getUser = createAsyncThunk('users/getUser',
  async ({ userId }, thunkAPI) => {
    try {
      const { data } = await ApiService.get(`/users/${userId}`);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const getJoinedGroups = createAsyncThunk('users/getJoinedGroups',
  async ({ userId }, thunkAPI) => {
    try {
      const { data } = await ApiService.get(`/users/${userId}/joined_groups`);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const banUser = createAsyncThunk('users/banUser',
  async ({ userId, groupId }, thunkAPI) => {
    try {
      const { data } = await ApiService.post('/users/ban', { userId, groupId });
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const unbanUser = createAsyncThunk('users/unbanUser',
  async ({ userId, groupId }, thunkAPI) => {
    try {
      const { data } = await ApiService.post('/users/unban', { userId, groupId });
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });
