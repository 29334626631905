import { message } from 'antd';
import { formatDistanceToNow } from 'date-fns';

export const handleAPIError = (err, thunkAPI) => {
  const error = err.response?.data?.message;

  message.error(error);
  return thunkAPI.rejectWithValue({ error });
};

export const getFormattedTime = (time = '') => {
  if (!time) return '-';

  const date = new Date(time);
  const formatted = formatDistanceToNow(date);
  return `${formatted} ago`;
};
