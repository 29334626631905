import {
  MessageOutlined,
  TeamOutlined,
  SettingOutlined,
} from '@ant-design/icons';

// eslint-disable-next-line import/prefer-default-export
export const ROOT_LINKS = [
  {
    key: 0,
    type: 'link',
    text: 'Groups',
    to: '/',
    icon: <MessageOutlined />,
  },
  {
    key: 1,
    type: 'link',
    text: 'Users',
    to: '/users',
    icon: <TeamOutlined />,
  },
  {
    key: 2,
    type: 'link',
    text: 'Settings',
    to: '/settings',
    icon: <SettingOutlined />,
  },
];
