import { createSlice } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'utils/constants';
import {
  fetchGroups,
  getParticipants,
} from './service';

const initialState = {
  data: [],
  total: 0,
  selectedGroup: {},
  groupParticipants: [],

  fetchLoading: false,
  participantsLoading: false,

  sortBy: { createdAt: -1 },
  filters: {},
  offset: 0,
  limit: ITEMS_PER_PAGE,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    updatePage: (state, { payload }) => { state.offset = payload; },
  },
  extraReducers: {
    // Fetch users
    [fetchGroups.pending]: (state) => { state.fetchLoading = true; },

    [fetchGroups.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.total = payload.total;
      state.fetchLoading = false;
    },

    [fetchGroups.rejected]: (state) => { state.fetchLoading = false; },

    // Get participants
    [getParticipants.pending]: (state) => { state.participantsLoading = true; },

    [getParticipants.fulfilled]: (state, { payload }) => {
      state.groupParticipants = payload;
      state.participantsLoading = false;
    },

    [getParticipants.rejected]: (state) => { state.participantsLoading = false; },
  },
});

export const groupActions = {
  fetchGroups,
  getParticipants,
  ...groupsSlice.actions,
};

export default groupsSlice;
