import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { ROOT } from './constants';

const NotFound = () => (
  <>
    <Link to={ROOT}>Home</Link>
    <Typography.Title level={3}>404: page not found!</Typography.Title>
  </>
);

export default NotFound;
