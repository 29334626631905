import qs from 'qs';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from 'services/APIService';
import { handleAPIError } from 'utils/helpers';

export const fetchGroups = createAsyncThunk('groups/fetch',
  async ({
    filters, sortBy, offset, limit,
  }, thunkAPI) => {
    const queryString = qs.stringify({
      filters,
      sort_by: sortBy,
      offset: offset * limit,
      limit,
    }, { encode: false });

    try {
      const { data } = await ApiService.get(`/groups?${queryString}`);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });

export const getParticipants = createAsyncThunk('groups/getParticipants',
  async ({
    groupId,
  }, thunkAPI) => {
    try {
      const { data } = await ApiService.get(`/groups/${groupId}/participants`);
      return data;
    } catch (err) {
      return handleAPIError(err, thunkAPI);
    }
  });
