import {
  Descriptions,
  Typography,
  Tag,
} from 'antd';
import { useSelector } from 'react-redux';
import { format, utcToZonedTime } from 'date-fns-tz';

const { Link } = Typography;

const UserInformation = () => {
  const { selectedUser: user } = useSelector((state) => state.users);

  const getLastCheckedinAt = () => {
    let lastCheckedinAt = '';
    if (user?.lastCheckedinAt) {
      const date = new Date(user?.lastCheckedinAt);
      const timeZone = 'Asia/Yangon';
      const zonedDate = utcToZonedTime(date, timeZone);

      lastCheckedinAt = format(zonedDate, 'dd LLL u hh:mm aaa (z)', { timeZone });
    }

    return lastCheckedinAt;
  };

  return (
    <Descriptions size="small" column={2}>
      <Descriptions.Item label="Name">{user?.telegramName}</Descriptions.Item>
      <Descriptions.Item label="Login Status">
        {user?.isLoggedIn ? (
          <Tag color="green">Logged In</Tag>
        ) : (
          <Tag color="red">Logged Out</Tag>
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Telegram Username">
        <Link href={`https://t.me/${user?.telegramUserName}`} target="_blank" rel="noopener noreferrer">
          @
          {user?.telegramUserName}
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label="Last Checkin Time">{getLastCheckedinAt()}</Descriptions.Item>
      <Descriptions.Item label="Language">{user?.language === 'my' ? 'Myanmar' : 'English'}</Descriptions.Item>
    </Descriptions>
  );
};

export default UserInformation;
