import { useEffect, useState } from 'react';
import {
  PageHeader,
  Space,
  Table,
  Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ITEMS_PER_PAGE } from 'utils/constants';
import { groupActions } from 'state/groups';
import { formatDistanceToNow } from 'date-fns';
import GroupMembersModal from './GroupMembersModal';

const GroupsScreen = () => {
  const {
    data,
    total,
    fetchLoading,
    sortBy,
    filters,
    offset,
    limit,
  } = useSelector((state) => state.groups);
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});

  useEffect(() => {
    dispatch(groupActions.fetchGroups({
      sortBy,
      filters,
      offset,
      limit,
    }));
  }, []);

  const handlePageChange = (newPage) => {
    dispatch(groupActions.fetchGroups({
      sortBy,
      filters,
      offset: newPage - 1,
      limit,
    }));
    dispatch(groupActions.updatePage(newPage - 1));
  };

  const viewMembers = (group) => {
    setCurrentGroup(group);
    setModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Registered At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => {
        if (date) {
          return `${formatDistanceToNow(new Date(date))} ago`;
        }
        return '-';
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" style={{ padding: 0 }} onClick={() => viewMembers(record)}>View Members</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Groups"
        subTitle="All telegram groups the bot manages"
        style={{ backgroundColor: '#fff' }}
      />
      <div style={{ margin: 8, padding: 24, backgroundColor: '#fff' }}>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data}
          loading={fetchLoading}
          pagination={{
            current: offset + 1,
            pageSize: ITEMS_PER_PAGE,
            total,
            onChange: handlePageChange,
          }}
        />
      </div>
      <GroupMembersModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        group={currentGroup}
      />
    </>
  );
};

export default GroupsScreen;
