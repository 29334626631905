import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appLoaded: false,
  loading: false,
  authenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startApp: (state) => { state.appLoaded = true; },
    logout: (state) => {
      localStorage.removeItem('_cred');

      state.authenticated = false;
      state.user = null;
    },
    updateCurrentUser: (state, { payload }) => {
      state.user = payload;
    },
    setAuthUser: (state, { payload }) => {
      state.authenticated = true;
      state.appLoaded = true;
      state.user = payload;
    },
    updateProfileImage: (state, { payload }) => {
      state.user.profileImageUrl = payload;
    },
    setLoading: (state, { payload }) => { state.loading = payload; },

    reset: () => initialState,
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice;
