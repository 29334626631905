import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Layout,
  Menu,
  Typography,
  Divider,
} from 'antd';
import { ROOT_LINKS } from './links';

const {
  Header, Content, Sider,
} = Layout;

const { Title } = Typography;

const AppLayout = ({ children }) => {
  const [navLinks, setNavLinks] = useState([]);
  const [selected, setSelected] = useState('/');
  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname;

    setNavLinks(ROOT_LINKS);

    if (path.startsWith('/users')) {
      setSelected('/users');
    } else {
      setSelected(path);
    }
  }, [history.location.pathname]);

  return (
    <Layout style={{ height: '100vh' }}>
      <Header
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: 1,
          background: '#fff',
          padding: '0 25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <Title level={5} style={{ margin: 0, cursor: 'pointer' }} onClick={() => history.push('/')}>CI Bot Dashboard</Title>
      </Header>
      <Sider
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          marginTop: 64,
          borderRight: '1px solid #f0f0f0',
        }}
      >
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selected]}
          onSelect={(e) => setSelected(e.key)}
        >
          {navLinks.map((link) => {
            if (link.type === 'link') {
              return (
                <Menu.Item key={link.to} icon={link.icon}>
                  <Link to={link.to}>
                    {link.text}
                  </Link>
                </Menu.Item>
              );
            }

            return (
              <Menu.Item key={link.key} style={{ padding: 0, margin: 0, height: 1 }}>
                <Divider key={link.key} style={{ margin: '12px 0' }} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: 200,
          marginTop: 64,
        }}
      >
        <Content>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
