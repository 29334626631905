import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
export default function PrivateRoute({ children, ...rest }) {
  const { authenticated } = useSelector((state) => state.auth);

  const buildRoute = (location) => {
    if (!authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }

    return children;
  };

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => buildRoute(location)}
    />
  );
}
