import { createSlice } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'utils/constants';
import {
  fetchUsers,
  getUser,
  getJoinedGroups,
  banUser,
  unbanUser,
} from './service';

const initialState = {
  data: [],
  total: 0,
  selectedUser: {},
  joinedGroups: [],

  fetchLoading: false,
  getLoading: false,
  joinedGroupsLoading: false,

  sortBy: { createdAt: -1 },
  filters: {},
  offset: 0,
  limit: ITEMS_PER_PAGE,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updatePage: (state, { payload }) => { state.offset = payload; },

    updateDisabledStatus: (state, { payload }) => { state.selectedUser.isDisabled = payload; },

    updateUCStatus: (state, { payload }) => { state.selectedUser.isUnderControl = payload; },
  },
  extraReducers: {
    // Fetch users
    [fetchUsers.pending]: (state) => { state.fetchLoading = true; },

    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.total = payload.total;
      state.fetchLoading = false;
    },

    [fetchUsers.rejected]: (state) => { state.fetchLoading = false; },

    // Get user
    [getUser.pending]: (state) => { state.getLoading = true; },

    [getUser.fulfilled]: (state, { payload }) => {
      state.selectedUser = payload;
      state.getLoading = false;
    },

    [getUser.rejected]: (state) => { state.getLoading = false; },

    // Get joined groups
    [getJoinedGroups.pending]: (state) => { state.joinedGroupsLoading = true; },

    [getJoinedGroups.fulfilled]: (state, { payload }) => {
      state.joinedGroups = payload;
      state.joinedGroupsLoading = false;
    },

    [getJoinedGroups.rejected]: (state) => { state.joinedGroupsLoading = false; },

    // Ban user
    [banUser.fulfilled]: (state, { payload }) => {
      const group = state.joinedGroups.find((doc) => doc.group._id === payload.groupId);

      if (group) {
        group.status = 'INACTIVE';
      }
    },

    // Unban user
    [unbanUser.fulfilled]: (state, { payload }) => {
      const group = state.joinedGroups.find((doc) => doc.group._id === payload.groupId);

      if (group) {
        group.status = 'READY';
      }
    },
  },
});

export const userActions = {
  fetchUsers,
  getUser,
  getJoinedGroups,
  banUser,
  unbanUser,
  ...usersSlice.actions,
};

export default usersSlice;
