import { useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions } from 'state/settings';

export default function SecuritySettings() {
  const [form] = Form.useForm();

  const { settings, updateLoading } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({ sosPin: settings?.sosPin });
  }, [settings]);

  const handlePinReset = async (data) => {
    console.log(data);

    dispatch(settingsActions.updateSettings(data));
    // try {
    //   setLoading(true);
    //   // await ApiService.post('/v1.0/users/update_password', data);
    message.success('SOS Pin reset.');
    //   setLoading(false);
    // } catch (err) {
    //   console.error(err);
    //   message.error(err?.response?.data?.message);
    //   setLoading(false);
    // }
  };

  return (
    <>
      {/* <Row>
        <Col>
          <Title level={4}>SOS Pin</Title>
        </Col>
      </Row> */}
      <Row gutter={30}>
        <Col span={10}>
          <Form
            layout="vertical"
            form={form}
            onFinish={handlePinReset}
            requiredMark={false}
          >
            <Form.Item
              name="sosPin"
              label="SOS Pin"
              rules={[
                { required: true, message: 'SOS pin cannot be empty.' },
                { pattern: /^\d{5}$/, message: 'Pin must be 5 digits' },
              ]}
              extra="Pin must be 5 digits (00000-99999)"
            >
              <Input.Password placeholder="Enter SOS pin" />
            </Form.Item>

            <Button htmlType="submit" type="primary" loading={updateLoading}>Update Pin</Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
